import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/config"; 


const Home = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                // Referencia al documento específico en la colección "slider"
                const sliderDoc = doc(db, "slider", "tBHEOZJEDQJMYhx7pJIX");
                const sliderSnapshot = await getDoc(sliderDoc);

                if (sliderSnapshot.exists()) {
                    const sliderData = sliderSnapshot.data();
                    console.log("Datos del documento:", sliderData);

                    // Verificar que `img` es un array
                    if (Array.isArray(sliderData.img)) {
                        setImages(sliderData.img);
                    } else {
                        console.error("El campo 'img' no es un array:", sliderData.img);
                    }
                } else {
                    console.error("El documento no existe en la colección 'slider'.");
                }
            } catch (error) {
                console.error("Error al obtener las imágenes de Firebase:", error);
            }
        };

        fetchImages();
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <div className="windows-home">
            <div className="windows-home-slider">
                {images.length > 0 ? (
                    <Slider {...sliderSettings}>
                        {images.map((imgUrl, index) => (
                            <div key={index}>
                                <img
                                    src={imgUrl}
                                    alt={`Slide ${index + 1}`}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <p>Cargando imágenes...</p>
                )}
            </div>
        </div>
    );
}
 
export default Home;