// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBqoPoHkIMZPaF7EojTOO1fzg2biDsFW6Y",
    authDomain: "diegogarcia-69859.firebaseapp.com",
    projectId: "diegogarcia-69859",
    storageBucket: "diegogarcia-69859.firebasestorage.app",
    messagingSenderId: "845278395892",
    appId: "1:845278395892:web:4d79a296fe01957abd8da1",
    measurementId: "G-CCJVCJM43X"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
