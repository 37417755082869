import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './routes/appRouter';

function App() {
  return (
    <div className='body-page'>
      <AppRouter />
      <ToastContainer />
    </div>
  );
}

export default App;
