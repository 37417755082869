import "./formsregister.scss";
import { useCallback, useState } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../config/config';
import { BiCheckCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";


const FormsRegister = ({setIsWindow, languaje}) => {
    const navigate = useNavigate();
    const [loanding, setLoanding] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    const validatePhone = useCallback((phone) => {
        const regex = /^[0-9]*$/;
        return regex.test(phone);
    }, []);

    const handlePhoneChange = useCallback((e) => {
        const value = e.target.value;
        if (validatePhone(value) || value === '') {
            handleChange(e);
        }
    }, [handleChange, validatePhone]);

    const toggleIsWindow = () => {
        setIsWindow(prevState => !prevState);
        navigate(-1); // Navega a la ruta anterior
    };

    const validateEmail = useCallback((email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (validateEmail(formData.email) && validatePhone(formData.phone)) {
            setLoanding(true);
            try {
                // Guardar los datos en Firestore
                await addDoc(collection(db, "forms_register"), formData);
    
                // Llamar a la función de Firebase para enviar el correo
                const response = await fetch(
                    "https://us-central1-diegogarcia-69859.cloudfunctions.net/sendEmail", // URL de tu función
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(formData),
                    }
                );
    
                const result = await response.json();
                if (result.success) {
                    toast.success("The data was sent successfully and the email has been sent."); // Traducción: Success - "Datos enviados correctamente y correo enviado."
                } else {
                    toast.error("There was an error sending the email: " + result.message); // Traducción: Error sending email - "Error enviando correo"
                }
    
                // Limpiar el formulario y mostrar el mensaje de éxito
                setFormData({
                    email: '',
                    phone: '',
                    message: '',
                });
                setSubmitted(true);
                setLoanding(false); 
            } catch (error) {
                setLoanding(false);
                toast.error("An error occurred while processing the request: " + error.messag); // Traducción: Error processing the request - "Error procesando la solicitud"
            }
        } else {
            setLoanding(false);
            toast.error("The entered data is not valid."); // Traducción: Invalid data - "Datos no válidos"
        }
    }, [formData, validateEmail, validatePhone]);    
    
    return (
        <>
            {!submitted 
                ?<>
                    {!loanding
                        ?<section className="section-forms-register">
                            {languaje==="EN" 
                                ?<>
                                    <h2 className="form-title">CONTACT</h2>
                                    <p className="form-text">For inquiries, collaborations and projects, please contact me.</p>
                                </>
                                :<>
                                    <h2 className="form-title">CONTACTO</h2>
                                    <p className="form-text">Para consultas, colaboraciones y proyectos, por favor contáctame.</p>
                                </>
                            }
                            <form onSubmit={handleSubmit}>
                                <div className="input-date">
                                    <label>Email:</label>
                                    <input type="email" placeholder="From" name="email" value={formData.email} onChange={handleChange} required />
                                </div>
                                <div className="input-date">
                                    {languaje==="EN" ?<label>Phone:</label>: <label>Teléfono:</label>}
                                    <input type="text"  name="phone" value={formData.phone} onChange={handlePhoneChange} required />
                                </div>
                                <div className="text-date">
                                    {languaje==="EN" ?<label>Message:</label>: <label>Mensaje</label>}
                                    <textarea name="message" placeholder="Write your message..." value={formData.message} onChange={handleChange} required />
                                </div>
                                <button className='button-form' type="submit">{languaje==="EN" ?<>Send</>:<>Enviar</>}</button>
                            </form>
                        </section>
                        :<>{languaje==="EN" ?<h2 className="form-title">Loading data... </h2>: <h2 className="form-title">Cargando datos... </h2>} </>
                    }
                </>
                :<section className="section-forms-register">
                    <div className="form-icon">
                        <BiCheckCircle />
                    </div>
                    {languaje==="EN" 
                        ?<>
                            <h2 className="form-title">Thanks for contact me! </h2>
                            <p className="form-text2">I will get back to you as soon as possible.</p>
                            <button className='button-form' onClick={toggleIsWindow} > <MdOutlineKeyboardArrowLeft /> Go back</button>
                        </>
                        :<>
                            <h2 className="form-title">Gracias por conectarte! </h2>
                            <p className="form-text2">Me comunicaré contigo lo antes posible..</p>
                            <button className='button-form' onClick={toggleIsWindow} > <MdOutlineKeyboardArrowLeft /> Volver</button>
                        </>
                    }
                </section>
            }
        </>
    );
}
 
export default FormsRegister;