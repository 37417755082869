import { BiArchiveIn, BiCreditCardFront, BiVideo } from "react-icons/bi";

const InfoPdf = ({ languaje }) => {
    const files = {
        resume: "https://firebasestorage.googleapis.com/v0/b/diegogarcia-69859.firebasestorage.app/o/pdf%2FDIEGO%20GARCIA%20%E2%80%A2%20CV_2024.pdf?alt=media&token=0fc91aad-3958-4c35-98a1-8f74ea52f49d",
        filmography: "https://firebasestorage.googleapis.com/v0/b/diegogarcia-69859.firebasestorage.app/o/pdf%2FDIEGO%20GARCIA%20%E2%80%A2%20FILMOGRAPHY_%202024.pdf?alt=media&token=8e1f4ca2-7a73-4f6c-b9bf-b7bf45c7db69",
        portfolio: "https://firebasestorage.googleapis.com/v0/b/diegogarcia-69859.firebasestorage.app/o/pdf%2FDIEGO%20GARCIA%20%E2%80%A2%20PORTFOLIO_2024.pdf?alt=media&token=ecca716e-f2c6-490b-8083-afb2c316b50c",
    };

    return (
        <section className="section-forms-register">
            {languaje === "EN" ? (
                <>
                    <h2 className="form-title">COLLABORATIONS</h2>
                    <p className="form-text">
                        You can find here part of my creative journey and some of my memories, challenges and process with incredible teams and unforgettable human beings.
                    </p>
                    <form >
                        <a href={files.filmography} className="button-dow" target="_blank" rel="noopener noreferrer">
                            <BiVideo />
                            Filmography
                        </a>
                        <a href={files.portfolio} className="button-dow" target="_blank" rel="noopener noreferrer">
                            <BiArchiveIn />
                            Portfolio 
                        </a>
                        <a href={files.resume} className="button-dow" target="_blank" rel="noopener noreferrer">
                            <BiCreditCardFront />
                            Resume
                        </a>
                    </form>
                </>
            ) : (
                <>
                    <h2 className="form-title">COLABORACIONES</h2>
                    <p className="form-text">
                        Puedes encontrar aquí parte de mi viaje creativo y algunos de mis recuerdos, callengue y proceso con equipos increíbles y seres humanos inolvidables.
                    </p>
                    <form >
                        <a href={files.filmography} className="button-dow" target="_blank" rel="noopener noreferrer">
                            <BiVideo />
                            Filmografía
                        </a>
                        <a href={files.portfolio} className="button-dow" target="_blank" rel="noopener noreferrer">
                            <BiArchiveIn />
                            Portafolio
                        </a>
                        <a href={files.resume} className="button-dow" target="_blank" rel="noopener noreferrer">
                            <BiCreditCardFront />
                            Hoja de vida
                        </a>
                    </form>
                </>
            )}
        </section>
    );
};

export default InfoPdf;
