import "./poppup.scss";
import { IoMdClose } from "react-icons/io";
import FormsRegister from "../../ui/formsregister/formsregister";
import InfoPdf from "../../ui/infopdf/infopdf";
import { useLocation, useNavigate } from "react-router-dom";

const Poppup = ({setIsWindow, languaje}) => {
    const navigate = useNavigate();
    const location = useLocation(); 

    const toggleIsWindow = () => {
        setIsWindow((prevState) => !prevState);
        navigate(-1); // Navega a la ruta anterior
    };

    const currentPath = location.pathname;
    const isCollaboration = currentPath === "/collaborations";
    const isContact = currentPath === "/contact";

    return (
        <div className="pop-pup">
            <button onClick={toggleIsWindow} className="close-button">
                <IoMdClose />
            </button>
            {isCollaboration && <InfoPdf languaje={languaje} />}
            {isContact && <FormsRegister languaje={languaje} setIsWindow={setIsWindow}/>}
        </div>
    );
}
 
export default Poppup;