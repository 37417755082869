import { LiaImdb } from "react-icons/lia";
import "./footer.scss";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import { useLocation } from "react-router-dom";

const Footer = ({languaje}) => {
    const location = useLocation();
    const isFooter = location.pathname === "/";

    return (
        <section className="footer">
            {isFooter
                ?<div className="footer-home">
                    <button onClick={() => window.location.href = "mailto:contact@diegarcia.com"}>
                        <MdOutlineEmail />
                        contact@diegarcia.com
                    </button>
                    <button onClick={() => window.open("https://www.imdb.com/name/nm5952939/", "_blank")}>
                        <LiaImdb />
                        Diego Garcia
                    </button>
                    <button onClick={() => window.open("https://instagram.com/die_garcia", "_blank")}>
                        <FaInstagram />
                        @die_garcia
                    </button>
                </div>
                :<div className="footer-info">
                    <div className="footer-column">
                        {languaje==="EN" 
                            ? <h3>AVAILABLE WORLDWIDE </h3>
                            : <h3>DISPONIBLE EN TODO EL MUNDO </h3>
                        }
                        <button onClick={() => window.open("https://www.google.com/maps/place/Cl.+74a+%2320c-43,+Bogot%C3%A1/@4.6637026,-74.0628981,19z/data=!4m6!3m5!1s0x8e3f9a59cb251385:0xba59101add1a765e!8m2!3d4.6638951!4d-74.0640152!16s%2Fg%2F11y7rhpy9k?entry=ttu&g_ep=EgoyMDI0MTIwMS4xIKXMDSoASAFQAw%3D%3D", "_blank")}>
                            <p>BOGOTÁ DC-COL<br></br>
                            CLL 74a #20c-43, Atelier Tropical
                            </p>
                        </button>
                        <button onClick={() => window.location.href = "tel:+573213392654"}>
                            <TbPhoneCall />
                            (+57)321-339 26 54
                        </button>
                    </div>
                    <div className="footer-column">
                        {languaje==="EN" 
                            ? <h3>CONTACT</h3>
                            : <h3>CONTACTO</h3>
                        }
                        <button onClick={() => window.location.href = "mailto:contact@diegarcia.com"}>
                            <MdOutlineEmail />
                            contact@diegarcia.com
                        </button>
                        <button onClick={() => window.open("https://www.imdb.com/name/nm5952939/", "_blank")}>
                            <LiaImdb />
                            Diego Garcia
                        </button>
                        <button onClick={() => window.open("https://instagram.com/die_garcia", "_blank")}>
                            <FaInstagram />
                            @die_garcia
                        </button>
                    </div>
                </div> 
            }
            <div className="copy-rights">
                {languaje==="EN" 
                    ?<>
                        <p>© ALL RIGHTS RESERVED DIEGO GARCIA 2025</p>
                        <p>Developed by @disvictormontoya</p>
                    </>
                    :<>
                        <p>©DERECHOS RESERVADOS DIEGO GARCIA 2025</p>
                        <p>Desarrollado por @disvictormontoya</p>
                    </>
                }
                
            </div>
        </section>
    );
}
 
export default Footer;
