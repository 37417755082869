import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// Importación de elementos
import Header from '../ui/header/header';
import Footer from '../ui/footer/footer';
import Poppup from '../pages/poppup/poppup';
import Roots from '../pages/roots/roots';
import Home from '../pages/home/home';

const AppContent = ({ isWindow, setIsWindow, languaje, setLanguaje }) => {
    return (
        <>
            {/* Mostrar la ventana emergente si isWindow es true */}
            {isWindow && <Poppup setIsWindow={setIsWindow} languaje={languaje} />}
            <Header setIsWindow={setIsWindow} languaje={languaje} setLanguaje={setLanguaje} />
            <div className='container-body'>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/roots' element={<Roots languaje={languaje} />} />
                </Routes>
                <Footer languaje={languaje} />
            </div>
        </>
    );
};

const AppRouter = () => {
    const [isWindow, setIsWindow] = useState(false); // Estado para controlar el popup
    const [languaje, setLanguaje] = useState("EN");

    return (
        <BrowserRouter>
            <AppContent
                isWindow={isWindow}
                setIsWindow={setIsWindow}
                languaje={languaje}
                setLanguaje={setLanguaje}
            />
        </BrowserRouter>
    );
};

export default AppRouter;
