import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/config"; 
import "./roots.scss"

const about = [
    {
        "EN":`
            My name is Diego García, and I was born in Bogotá, Colombia. 
            Growing up during the transition from analog to digital in the 1990s, 
            I was raised in a family rooted in tobacco plantations, the kitchens 
            of my rural grandmothers, photography, and the craftsmanship of my 
            grandfathers. This rich legacy is something I carry with me wherever 
            I live around the world. <br><br>

            I hold degrees in Industrial Design and Fine Arts, and I have 15 years 
            of experience in the film industry, working in the Art Department 
            in areas such as Construction, Set Decoration, and Props. 
            This craft has allowed me to gain the expertise necessary 
            to tackle projects of varying complexities—ranging from working 
            across diverse geographies, locations, and soundstages,
            to installing artworks in galleries and museums, and even building 
            my own home in Bogotá:  <a href="https://www.archdaily.co/co/1019018/atelier-tropical-yemail-arquitectura" target="_blank">The Atelier Tropical.</a> <br><br>

            As a Production Designer, my notable works include: 
            “Entre Tú y Milagros” directed by Mariana Saffon (2020),
            winner of the Orizzonti Award for Best Short Film at 
            the 77th Venice Biennale; and “Las Guerreras” directed 
            by Fabián Hernández (2024). <br>
            Additionally, as an Art Director, 
            I have contributed to feature films such as “Dora and the 
            Search for Sol Dorado” (2024), “Seal Team,” S7 (2024), 
            “The Luckiest Man in America” (2023), “Paddington in 
            Peru” (2023), and “Haunted Heart” (2022).<br><br>

            In 2020, I participated in the Production Design 
            Studio of the <a href="https://www.berlinale-talents.de/bt/page/c/main" target="_blank">Berlinale</a> Talent Campus. In 2022, 
            I joined the PDC <a href="https://www.productiondesignerscollective.org/" target="_blank">(Production Designers Collective)</a>, 
            a global network of Production Designers. From 2023 
            to 2024, I served as President of ATAAC <a href="https://www.asociacionataac.com/" target="_blank">
            (Asociación de Trabajadores del Arte para el Audiovisual en Colombia)</a> .<br><br>

            These experiences, across diverse territories, have provided me with
            the skills to approach projects of varying narrative, visual, 
            aesthetic, technical, and conceptual scales.<br>
        `
    },
    {
        "SP":`
            Mi nombre es Diego García y nací en Bogotá, Colombia. 
            En medio de la transformación entre lo análogo a lo digital en los 90s,
            crecí en una familia que me formó entre las plantaciones de tabaco, 
            la cocina de mis abuelas campesinas, las fotografías y la devoción 
            por la construcción de mis abuelos, sin duda, un legado familiar que 
            cargo conmigo en cualquier parte del mundo donde esté viviendo.<br><br>

            Soy formado en Diseño Industrial y Bellas Artes, tengo 15 años de 
            experiencia en la industria cinematográfica trabajando en el departamento 
            de Arte: Construcción, Decoración y Utilería. Este oficio me ha permitido 
            adquirir la experiencia necesaria para abordar proyectos de distintas complejidades; 
            pasando entre diversas geografías, locaciones y stages, instalando obras de arte en galerías y museos,  
            hasta construir mi propia casa en la capital colombiana <a href="https://www.archdaily.co/co/1019018/atelier-tropical-yemail-arquitectura" target="_blank">El Atelier Tropical.</a> <br><br>

            Entre mis trabajos como Diseñador de Producción destaco proyectos
            como: “Entre Tú y Milagros” dirigido por Mariana Saffon (2020), 
            ganador del Orizzonti Award for Best Short Film en la muestra 77 
            de La Biennale di Venezia; “Las Guerreras” dirigido por Fabián 
            Hernández (2024). Asimismo, desde la Dirección de Arte en largometrajes 
            como “Dora and the search for Sol Dorado” (2024), “Seal Team,” S7 (2024), 
            “The Luckiest Man in America” (2023), “Paddington in Peru” (2023), 
            y “Haunted Heart” (2022).<br><br>

            En el año 2020, participé en el Production Design Studio de Berlin 
            Talent Campus de la <a href="https://www.berlinale-talents.de/bt/page/c/main" target="_blank">Berlinale</a>. En el 2022, me vinculé al PDC 
             <a href="https://www.productiondesignerscollective.org/" target="_blank">“Production Designers Collective” el colectivo global de Diseñadores</a> 
            de Producción, y del 2023 al 2024 fui presidente de <a href="https://www.asociacionataac.com/" target="_blank">ATAAC</a> “Asociación 
            de Trabajadores del Arte para el Audiovisual en Colombia”. <br><br>

            Estos viajes a diversos territorios me han brindado la experiencia
            necesaria para abordar proyectos de distintas escalas narrativas, 
            visuales, estéticas, técnicas y conceptuales.<br><br>
        `
    }
];

const Roots = ({languaje}) => {
    const [images, setImages] = useState([]);
    const [titleImages] = useState([ // Imágenes para la sección windows-home-title-img
        "https://firebasestorage.googleapis.com/v0/b/diegogarcia-69859.firebasestorage.app/o/brand%2FMesa%20de%20trabajo%204_1.png?alt=media&token=2ee6fb83-b272-4bcc-b8ee-5a27461598be",
        "https://firebasestorage.googleapis.com/v0/b/diegogarcia-69859.firebasestorage.app/o/brand%2FMesa%20de%20trabajo%204.png?alt=media&token=ea57e99f-8f17-46ef-922c-0306713987aa",
    ]);
    const [currentTitleImageIndex, setCurrentTitleImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                // Referencia al documento específico en la colección "slider"
                const sliderDoc = doc(db, "slider", "tBHEOZJEDQJMYhx7pJIX");
                const sliderSnapshot = await getDoc(sliderDoc);

                if (sliderSnapshot.exists()) {
                    const sliderData = sliderSnapshot.data();
                    console.log("Datos del documento:", sliderData);

                    // Verificar que `img` es un array
                    if (Array.isArray(sliderData.img)) {
                        setImages(sliderData.img);
                    } else {
                        console.error("El campo 'img' no es un array:", sliderData.img);
                    }
                } else {
                    console.error("El documento no existe en la colección 'slider'.");
                }
            } catch (error) {
                console.error("Error al obtener las imágenes de Firebase:", error);
            }
        };

        fetchImages();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true);
            setTimeout(() => {
                setCurrentTitleImageIndex((prevIndex) => (prevIndex + 1) % titleImages.length);
                setIsFading(false);
            }, 1000); 
        }, 5000); 

        return () => clearInterval(interval);
    }, [titleImages]);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <div className="windows-home">
            <div className="windows-home-slider">
                {images.length > 0 ? (
                    <Slider {...sliderSettings}>
                        {images.map((imgUrl, index) => (
                            <div key={index}>
                                <img
                                    src={imgUrl}
                                    alt={`Slide ${index + 1}`}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <p>Cargando imágenes...</p>
                )}
            </div>
            <div className="windows-home-title">
                {languaje==="EN" 
                    ? <h2 className="home-title-h">PRODUCTION DESIGNER<br></br>ART DIRECTOR</h2>
                    : <h2 className="home-title-h">DIRECTOR DE ARTE<br></br>DISEÑADOR DE PRODUCCIÓN</h2>
                }
                <div className="windows-home-title-img">
                    <img
                        src={titleImages[currentTitleImageIndex]}
                        alt="About Diego"
                        className={`rotating-image ${isFading ? "fade-out" : "fade-in"}`}
                    />
                </div>
            </div>
            <div className="windows-home-text">
                <p
                    dangerouslySetInnerHTML={{
                        __html: about.find((item) => item[languaje])[languaje]
                    }}
                />
            </div>
        </div>
    );
}
 
export default Roots;