import { Link, useLocation } from "react-router-dom";
import "./header.scss";

const Header = ({ setIsWindow, setLanguaje, languaje }) => {
    const location = useLocation();
    const isHome = location.pathname === "/";

    const toggleIsWindow = () => {
        setIsWindow((prevState) => !prevState);
    };

    const toggleEn = () => {
        setLanguaje("EN");
    };

    const toggleSp = () => {
        setLanguaje("SP");
    };

    return (
        <header className="header-navbar">
            <section className="language">
                <p>{languaje === "EN" ? <>Language</> : <>Lenguaje</>}</p>: 
                <button onClick={toggleEn}>EN</button> | <button onClick={toggleSp}>SP</button>
            </section>
            <section className="navbar">
                <Link to={"/"}>{languaje === "EN" ? <>HOME</> : <>HOME</>}</Link>
                <Link 
                    to={"/roots"} 
                >
                    {languaje === "EN" ? <>ROOTS</> : <>ORIGEN</>}
                </Link>
                <Link 
                    to={"/collaborations"} 
                    onClick={toggleIsWindow}
                >
                    {languaje === "EN" ? <>COLLABORATIONS</> : <>COLABORACIONES</>}
                </Link>
                <Link 
                    to={"/contact"} 
                    onClick={toggleIsWindow}
                >
                    {languaje === "EN" ? <>CONTACT</> : <>CONTACTO</>}
                </Link>
            </section>
            <section  className={`navbar-title ${isHome ? "center-title" : ""}`}>
                <h1>DIE.GARCÍA</h1>
                {isHome&&<h4>{languaje === "EN" ? <>PRODUCTION DESIGNER<br></br>ART DIRECTOR</> : <>DISEÑADOR DE PRODUCCIÓN<br></br>DIRECTOR DE ARTE</>}</h4>}
            </section>
        </header>
    );
};

export default Header;

